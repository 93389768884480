// extracted by mini-css-extract-plugin
export var heading = "client-section-module--heading--xh-zs";
export var image = "client-section-module--image--MdZ7M";
export var imageContainer = "client-section-module--imageContainer--cr-2R";
export var leftQuoteIcon = "client-section-module--leftQuoteIcon--mCN2D";
export var quote = "client-section-module--quote--83cwI";
export var quoteCompany = "client-section-module--quoteCompany--+dv0w";
export var quoteContainer = "client-section-module--quoteContainer--8ebL5";
export var rightQuoteIcon = "client-section-module--rightQuoteIcon--V1tTX";
export var root = "client-section-module--root--XvI0d";
export var testimonialsContainer = "client-section-module--testimonialsContainer--9UDLk";
export var textContainer = "client-section-module--textContainer--Pmbvs";
export var title = "client-section-module--title--gLbZr";